import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../../css/OwlSlider.css";

const OwlSlider = ({ packages }) => {
  const responsive = {
    0: {
      items: 1,
    },
    600: {
      items: 3,
    },
    1000: {
      items: 5,
    },
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
      minimumFractionDigits: 0,
    }).format(price);
  };

  return (
    <div className="slider">
      <OwlCarousel
        className="owl-theme"
        loop
        margin={10}
        nav={false}
        dots={false}
        responsive={responsive}
        autoplay
        autoplayTimeout={5000}
      >
        {packages.map((item) => (
          <a
            key={item.sitepackage_id}
            href={`/packages/${item.sitepackage_id}`}
          >
            <div className="item">
              <img src={item.sp_image} alt={`Package ${item.sitepackage_id}`} />
              <div className="overlay">
                <div className="text">
                  <h4>{item.sp_name}</h4>
                  <p>{formatPrice(item.sp_startprice)}</p>
                </div>
              </div>
            </div>
          </a>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default OwlSlider;
