import React from "react";
import PageLayout from "../components/layout/pageLayout"; 

const FAQs = ({ siteData }) => {
  const faqData = siteData?.sitefaqs || [];

  return (
    <PageLayout pageTitle="Frequently Asked Questions">
      <section className="faq-section py-5">
        <div className="container">
          {faqData.map((faq, index) => (
            <div key={faq.faqs_id} className="mb-4">
              <h3 className="faq-question">{faq.f_question}</h3>
              <div
                className="faq-answer"
                dangerouslySetInnerHTML={{
                  __html: faq.f_answer,
                }}
              ></div>
            </div>
          ))}
        </div>
      </section>
    </PageLayout>
  );
};

export default FAQs;
