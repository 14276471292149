import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import PageLayout from "../components/layout/pageLayout";
import { serverUrl, appKey } from "../components/Controllers/Config";
import Map from "../components/widgets/Map";
import PackageForm from "../components/forms/PackageForm";
import ImageGallery from "react-image-gallery";

const Packages = ({ siteData }) => {
  const [loading, setLoading] = useState(true);
  const { PackageID } = useParams();
  const [packageData, setPackageData] = useState({});

  useEffect(() => {
    const fetchPackageData = async () => {
      try {
        const token = localStorage.getItem("hp_jwt");
        const response = await axios.get(
          `${serverUrl}/app/packagedata/${PackageID}`,
          {
            headers: {
              Authorization: `${token}`,
              APPKEY: `${appKey}`,
            },
          }
        );
        setPackageData(response.data[0]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching package data:", error);
        setLoading(false);
      }
    };

    fetchPackageData();
  }, [PackageID]);

  const formatPrice = (price) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
      minimumFractionDigits: 0,
    }).format(price);
  };

  const parseCoordinates = (coordinates) => {
    const [Lat, Lng] = coordinates
      .split(", ")
      .map((coord) => parseFloat(coord));
    return { Lat, Lng };
  };

  const parseGallery = (galleryString) => {
    try {
      return JSON.parse(galleryString).map((image) => ({
        original: image,
        thumbnail: image,
      }));
    } catch (error) {
      console.error("Error parsing gallery:", error);
      return [];
    }
  };

  return (
    <>
      {loading ? (
        <PageLayout pageTitle="View Package">
          <section className="faq-section py-5">
            <div className="container">
              <div>Loading...</div>
            </div>
          </section>
        </PageLayout>
      ) : (
        <PageLayout pageTitle={`${packageData.sp_name}`}>
          <section className="faq-section py-5">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <img
                    src={packageData.sp_image}
                    alt={`Package ${packageData.sp_name}`}
                    className="img-fluid"
                  />

                  <div className="mt-2">
                    {" "}
                    {packageData.s_url ? (
                      <>
                        <Link
                          to={packageData.sp_externalurl}
                          className="btn btn-sm btn-primary"
                        >
                          View More
                        </Link>
                        <br></br>
                      </>
                    ) : (
                      <></>
                    )}
                    {packageData.sp_address}
                  </div>
                  <div className="mt-2">
                    {packageData.sp_type !== "Real Estate" ? (
                      <p className="fw-bold">Booking Request</p>
                    ) : (
                      <p className="fw-bold">Book an Appointment</p>
                    )}

                    <PackageForm
                      packageID={PackageID}
                      packageType={packageData.sp_type}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  {packageData.sp_gallery && (
                    <div className="">
                      <ImageGallery
                        items={parseGallery(packageData.sp_gallery)}
                        thumbnailPosition={"bottom"}
                        autoPlay
                      />
                    </div>
                  )}

                  {packageData.sp_type !== "Real Estate" ? (
                    <></>
                  ) : (
                    <h4 className="mt-2">
                      Price: KES {formatPrice(packageData.sp_startprice)}
                    </h4>
                  )}

                  <div
                    className="mt-2"
                    dangerouslySetInnerHTML={{
                      __html: packageData.sp_description,
                    }}
                  ></div>
                  <div className="mt-2">
                    {packageData.sp_coordinates && (
                      <Map {...parseCoordinates(packageData.sp_coordinates)} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </PageLayout>
      )}
    </>
  );
};

export default Packages;
