import React from "react";
import { NavLink } from "react-router-dom";
import menuItems from "./menuItems";
import { appName } from "./Controllers/Config";


const Navbar = () => {

 

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container">
        <NavLink className="navbar-brand" to="/">
          {appName}
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav me-auto">
            {menuItems.map((item, index) => (
              <li className="nav-item" key={index}>
                <NavLink
                  className="nav-link"
                  exact
                  to={item.link}
                  activeClassName="active"
                >
                  {item.label}
                </NavLink>
              </li>
            ))}
          </ul>
          <div className="d-flex">
            <NavLink
              className="btn btn-outline-light me-2"
              to="https://kalielltd.co.ke/login"
            >
              Login
            </NavLink>
            <NavLink
              className="btn btn-light"
              to="https://kalielltd.co.ke/register"
            >
              Register
            </NavLink>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
