import React, { useState, useEffect } from "react";
import Carousel from "../components/widgets/Carousel";
import SearchForm from "../components/widgets/SearchForm";
import SearchResults from "../components/widgets/SearchResults";
import OwlSlider from "../components/widgets/OwlSlider";
import { serverUrl, appKey } from "../components/Controllers/Config";
import axios from "axios";

const Home = ({ searchOptions, searchData, siteData }) => {
  const [searchFilters, setSearchFilters] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const [error, setError] = useState(null);
  const [carouselImages, setCarouselImages] = useState([]);
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);

   useEffect(() => {
     const fetchPackages = async () => {
       try {
         const response = await axios.get(`${serverUrl}/app/packages`, {
           headers: {
             Authorization: appKey,
           },
         });
         setPackages(response.data);
         setLoading(false);
       } catch (error) {
         console.error("Error fetching packages:", error);
         setLoading(false);
       }
     };

     fetchPackages();
   }, []);
     
useEffect(() => {
  if (siteData && siteData.sitedata && siteData.sitedata.length > 0) {
    const s_info = JSON.parse(siteData.sitedata[0].s_info);

    const topSliderImages = s_info.Images || [];
    const livePackages = siteData.sitepackages || [];

    if (topSliderImages.length > 0) {
      setCarouselImages(topSliderImages);
    } else {
      setCarouselImages([
        "https://via.placeholder.com/1920x720?text=Slider+Image+1",
        "https://via.placeholder.com/1920x720?text=Slider+Image+2",
        "https://via.placeholder.com/1920x720?text=Slider+Image+3",
      ]);
    }

    if (livePackages.length > 0) {
      setPackages(livePackages);
    }
  } else {
    setCarouselImages([
      "https://via.placeholder.com/1920x720?text=Slider+Image+1",
      "https://via.placeholder.com/1920x720?text=Slider+Image+2",
      "https://via.placeholder.com/1920x720?text=Slider+Image+3",
    ]);
  }
}, [siteData]);



  const handleSearchOptions = (data) => {
    console.log("Search Options::", data);
    setSearchFilters(data);
  };

  const handleSearchData = (results) => {
    console.log("Search Results::", results);
    setSearchResults(results);
  };

  const handleError = (error) => {
    console.error("Error occurred::", error);
    setError(error);
  };

  const handleSearching = (bool) => {
    setSearching(bool);
  };

  const getDefaultLocationFromUrl = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("location") || "";
  };

  return (
    <div>
      <main>
        <Carousel images={carouselImages} />

        <h4 className="text-center">Get accommodation now</h4>
        <div className="container searchform">
          <SearchForm
            defaultLocation={getDefaultLocationFromUrl()}
            onSearch={async (results) => {
              handleSearchData(results);
            }}
            onError={handleError}
            onSearchOptions={async (options) => {
              handleSearchOptions(options);
            }}
            onSearching={handleSearching}
          />
        </div>
        <div className="searchresults">
          <div className="container">
            {searching ? (
              <p>Searching...</p>
            ) : error ? (
              <div className="alert alert-danger">
                <p>Error: {error.error}</p>
              </div>
            ) : searchResults.length > 0 ? (
              <SearchResults results={searchResults} options={searchFilters} />
            ) : (
              <div className="alert alert-warning">
                No accommodations available, please try a different search.
              </div>
            )}
          </div>
        </div>
        {loading ? (
          <div className="container text-center">
            <p>Fetching package deals...</p>
          </div>
        ) : (
          <OwlSlider packages={packages} />
        )}
      </main>
    </div>
  );
};

export default Home;
