import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import FAQs from "./pages/FAQs";
import usePageTitle from "./components/utils/pagetitle";
import NotFound from "./pages/404";
import axios from "axios"; 
import { serverUrl,appKey } from "./components/Controllers/Config";
import Packages from "./pages/Packages";

function Page(props) {
  const titlePrefix = " | HOTEL BOOKING SYSTEM";
  usePageTitle(`${props.title}${titlePrefix}`);
  return (
    <div>
      <Helmet>
        <title>
          {props.title}
          {titlePrefix}
        </title>
        <meta
          name="description"
          content={
            props.description ||
            "Your one-stop solution for hotel booking, providing high-quality service and support for all your travel needs."
          }
        />
        <meta
          name="keywords"
          content={
            props.keywords ||
            "hotel booking, travel, accommodation, vacation, hotels, reservations"
          }
        />
      </Helmet>
      {props.content}
    </div>
  );
}

const App = () => {
  const [searchOptions, setSearchOptions] = useState(null);
  const [searchData, setSearchData] = useState(null);
  const [siteData, setSiteData] = useState(null); // State to store site data

  // Fetch site data from the server or local storage
  useEffect(() => {
    const fetchSiteData = async () => {
      try {
        const storedSiteData = localStorage.getItem("siteData");
        if (storedSiteData) {
          const parsedSiteData = JSON.parse(storedSiteData);
          const storedTimestamp = parsedSiteData.timestamp;
          const weekInMilliseconds = 1 * 24 * 60 * 60 * 1000;
          const currentTimestamp = new Date().getTime();
          if (currentTimestamp - storedTimestamp < weekInMilliseconds) {
            // Use existing site data if it's not older than a week
            setSiteData(parsedSiteData.data);
            return;
          }
        }

        // Fetch site data from the server
        const response = await axios.get(`${serverUrl}/app/sitedata/`, {
          headers: {
            Authorization: appKey, // Set Authorization header to appKey
          },
        });

        // Parse the response data
        const newSiteData = response.data;

        // Store fetched site data in local storage
        const newSiteDataWithTimestamp = {
          data: newSiteData,
          timestamp: new Date().getTime(),
        };
        localStorage.setItem(
          "siteData",
          JSON.stringify(newSiteDataWithTimestamp)
        );

        setSiteData(newSiteData);
      } catch (error) {
        console.error("Error fetching site data:", error);
      }
    };


    fetchSiteData();
  }, []); // Empty dependency array ensures useEffect runs only once on component mount

  const handleSearchOptions = (options) => {
    setSearchOptions(options);
  };

  const handleSearchData = (data) => {
    setSearchData(data);
  };

  return (
    <>
      <HelmetProvider>
        <BrowserRouter>
          <Navbar />
          <div className="main">
            <ToastContainer autoClose={2000} />
            <Routes>
              <Route
                path="/"
                element={
                  <Page
                    title="Home"
                    content={
                      <Home
                        siteData={siteData}
                        onSearchOptions={handleSearchOptions}
                        onSearch={handleSearchData}
                      />
                    }
                  />
                }
              />
              <Route
                path="/contactus"
                element={
                  <Page
                    title="Contact Us"
                    description="Get in touch with us for any inquiries or support related to our hotel booking services."
                    keywords="contact, support, hotel booking, inquiries"
                    content={<ContactUs siteData={siteData} />}
                  />
                }
              />
              <Route
                path="/faqs"
                element={
                  <Page
                    title="FAQs"
                    description="Find answers to the most frequently asked questions about our hotel booking services."
                    keywords="faqs, questions, answers, hotel booking, help"
                    content={<FAQs siteData={siteData} />}
                  />
                }
              />
              <Route
                path="/packages/:PackageID"
                element={
                  <Page
                    title="View Package"
                    description="Find the best holiday and tour package deals with us."
                    content={<Packages siteData={siteData} />}
                  />
                }
              />
              <Route
                path="*"
                element={
                  <Page
                    title="404 Not Found"
                    description="The page you are looking for does not exist."
                    keywords="404, not found, error"
                    content={<NotFound />}
                  />
                }
              />
            </Routes>
          </div>
          <Footer />
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
};

export default App;
