// config.js
export const serverUrl = "https://server.kalielltd.co.ke/api";
export const appKey = "i2901OmkrcPSy6po8iECrrunGGITi4U5";
export const placeholderImage = '/assets/placeholder.png';
export const appName="THISISKENYA";
export const MAPBOX_ACCESS_TOKEN =
  "pk.eyJ1IjoiY29sbGluc25qb2thIiwiYSI6ImNrbDdoMW9iMjBmc28ycG5wNGtleHY1eTIifQ.NtWws1dirkvdOacUKGJ8TA";

  
export const formatAmount = (amount) => {
  if (amount > 0) {
    return amount.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }
  return null;
};