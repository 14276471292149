import React, { useState } from "react";
import axios from "axios";
import { serverUrl, appKey } from "../Controllers/Config";

const PackageForm = ({ packageID, packageType }) => {
  const [formData, setFormData] = useState({
    type:packageType,
    name: "",
    email: "",
    phone: "",
    details: {
      preferredTime: "",
      preferredDay: "",
      message: "",
    },
  });
  const [formDataa, setFormDataa] = useState({
    type: packageType,
    name: "",
    email: "",
    phone: "",
    details: {
      arrivalDate: "",
      departureDate: "",
      nightsNo: "",
      adultsNo: "",
      childrenNo: "",
      message: "",
    },
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (packageType != "Real Estate") {
      if (name.includes("details.")) {
        setFormDataa((prevData) => ({
          ...prevData,
          details: {
            ...prevData.details,
            [name.split(".")[1]]: value,
          },
        }));
      } else {
        setFormDataa((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    } else {
      if (name.includes("details.")) {
        setFormData((prevData) => ({
          ...prevData,
          details: {
            ...prevData.details,
            [name.split(".")[1]]: value,
          },
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };

  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const dataToSubmit = packageType != "Real Estate" ? formDataa : formData;

    if (
      !dataToSubmit.name.trim() ||
      !dataToSubmit.email.trim() ||
      !dataToSubmit.phone.trim() ||
      !dataToSubmit.details.message.trim()
    ) {
      setError("All fields are required.");
      setLoading(false);
      return;
    }

    if (!validateEmail(dataToSubmit.email)) {
      setError("Please enter a valid email address.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        `${serverUrl}/app/reservation/${packageID}`,
        dataToSubmit,
        {
          headers: {
            Authorization: appKey,
          },
        }
      );
      setSuccess(true);
      if (packageType != "Real Estate") {
        setFormDataa({
          name: "",
          email: "",
          phone: "",
          details: {
            arrivalDate: "",
            departureDate: "",
            nightsNo: "",
            adultsNo: "",
            childrenNo: "",
            message: "",
          },
        });
      } else {
        setFormData({
          name: "",
          email: "",
          phone: "",
          details: {
            preferredTime: "",
            preferredDay: "",
            message: "",
          },
        });
      }
      console.log("Contact form submitted successfully:", response.data);
    } catch (error) {
      setError("An error occurred while submitting the form.");
      console.error("Contact form submission error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="name" className="form-label">
          Name
        </label>
        <input
          type="text"
          className="form-control"
          id="name"
          name="name"
          value={packageType != "Real Estate" ? formDataa.name : formData.name}
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="email" className="form-label">
          Email
        </label>
        <input
          type="email"
          className="form-control"
          id="email"
          name="email"
          value={
            packageType != "Real Estate" ? formDataa.email : formData.email
          }
          onChange={handleChange}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="phone" className="form-label">
          Phone Number
        </label>
        <input
          type="text"
          className="form-control"
          id="phone"
          name="phone"
          value={
            packageType != "Real Estate" ? formDataa.phone : formData.phone
          }
          onChange={handleChange}
          required
        />
      </div>
      {packageType != "Real Estate" ? (
        <>
          <div className="mb-3">
            <label htmlFor="arrivalDate" className="form-label">
              Arrival Date
            </label>
            <input
              type="date"
              className="form-control"
              id="arrivalDate"
              name="details.arrivalDate"
              value={formDataa.details.arrivalDate}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="departureDate" className="form-label">
              Departure Date
            </label>
            <input
              type="date"
              className="form-control"
              id="departureDate"
              name="details.departureDate"
              value={formDataa.details.departureDate}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="nightsNo" className="form-label">
              Number of Nights
            </label>
            <input
              type="number"
              className="form-control"
              id="nightsNo"
              name="details.nightsNo"
              value={formDataa.details.nightsNo}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="adultsNo" className="form-label">
              Number of Adults
            </label>
            <input
              type="number"
              className="form-control"
              id="adultsNo"
              name="details.adultsNo"
              value={formDataa.details.adultsNo}
              onChange={handleChange}
              required
            />
          </div>
          <div className="mb-3">
            <label htmlFor="childrenNo" className="form-label">
              Number of Children
            </label>
            <input
              type="number"
              className="form-control"
              id="childrenNo"
              name="details.childrenNo"
              value={formDataa.details.childrenNo}
              onChange={handleChange}
              required
            />
          </div>
        </>
      ) : (
        <>
          <div className="mb-3">
            <label htmlFor="preferredTime" className="form-label">
              Preferred Contact Time
            </label>
            <select
              className="form-control"
              id="preferredTime"
              name="details.preferredTime"
              value={formData.details.preferredTime}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select a time
              </option>
              <option value="08:00AM to 12:00PM">08:00AM to 12:00PM</option>
              <option value="01:00PM to 03:00PM">01:00PM to 03:00PM</option>
              <option value="03:00PM to 07:00PM">03:00PM to 07:00PM</option>
              <option value="after 06:00PM">After 06:00PM</option>
              <option value="any time">Any time</option>
            </select>
          </div>
          <div className="mb-3">
            <label htmlFor="preferredDay" className="form-label">
              Preferred Contact Day
            </label>
            <select
              className="form-control"
              id="preferredDay"
              name="details.preferredDay"
              value={formData.details.preferredDay}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select a day
              </option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
              <option value="Sunday">Sunday</option>
            </select>
          </div>
        </>
      )}
      <div className="mb-3">
        <label htmlFor="message" className="form-label">
          Message
        </label>
        <textarea
          className="form-control"
          id="message"
          name="details.message"
          value={
            packageType != "Real Estate"
              ? formDataa.details.message
              : formData.details.message
          }
          onChange={handleChange}
          required
        />
      </div>
      {error && <div className="alert alert-danger">{error}</div>}
      {success && (
        <div className="alert alert-success">Form submitted successfully!</div>
      )}
      <button type="submit" className="btn btn-primary" disabled={loading}>
        {loading ? "Submitting..." : "Submit"}
      </button>
    </form>
  );
};

export default PackageForm;
